import React from 'react';
import './Proposal.css';

function DMVProposal() {

    return (
        <div class="wrapper">
            <h3>Vendor Day 2024</h3>


            <p>
                <a href="https://avereincusa-my.sharepoint.com/:w:/g/personal/lnepo_avereusa_com/EVZN6qR4S7JIvmkYzMIw1KUBv_5nrnY0smDB0XDdGHuEzA?e=Z2cxGN">
                    Chatbot Integration Proposal for DMV
                </a>
            </p>
        </div>
    );
}

export default DMVProposal;