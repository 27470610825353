import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

//About Us Component/ Our Services Component

function Cards() {
  return (
    <>

    <div className='cards'>
    <div className="aboutUs-wrapper">
      <h1>About Us</h1>
      <p>Avere is a Service Disabled Veteran Owned Business that has a rich history of providing IT Consulting 
        and IT Staffing services to the greater Sacramento region. 
        Through our 25 years of experience working with the State of California, we have gained invaluable 
        experience of the State and its operational and legislative nuances that are filtered down to each of its many agencies. 
        We’ve also been fortunate enough to develop strong partnerships with national and global firms. 
        With this unique background and experience, we are able to provide our State and Local Government 
        clients the perfect blend of personal touch and attention of a small, locally owned business with the experience and capabilities of large, multi-national firms. 
        We pride ourselves on the reputation we have established for delivering for our customers and partners in the Sacramento Region and the State of California.
        </p>
        </div>
        <h1>Our Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={require('../cybersecurity.jpg').default}
              text='Address your organization’s unique challenge of aligning security and business objectives to facilitate the development of robust security programs.'
              label='Cyber Security'
              path='/cybersecurity'
            />
            <CardItem
              src={require('../projectmanagement.jpg').default}
              text='Planning, organizing, and directing the completion of specific projects for your organization while ensuring these projects are on time, on budget, and within scope.'
              label='Project Management'
              path='/projectmanagement'
            />
            <CardItem
              src={require('../clouds.jpg').default}
              text='Designing, implementing, migrating, or maintaining your cloud applications, processes, or computing systems.'
              label='Cloud Solutions'
              path='/cloud'
            />
            </ul>
            <ul className='cards__items'>
            <CardItem
              src={require('../ceiling.jpg').default}
              text='Implementing technological solutions to solve business problems, creating a schedule for analysis, implementation, and testing of the technology, and working with in-house technical teams to ensure consistency of uses.'
              label='IT Advisory'
              path='/itadvisory'
            />
            <CardItem
              src={require('../staff.jpg').default}
              text="Finding qualified professionals that are a good fit for your organization's staffing needs."
              label='IT Staffing'
              path='/itstaffing'
            />
                        <CardItem
              src={require('../circle.jpg').default}
              text='Helping your organization through the SDLC process, offering our 25+ years of experience in project development.'
              label='SDLC Support'
              path='/sdlc'
            />
          </ul>
        </div>
      </div>
    </div>    
    </>
  );
}

export default Cards;